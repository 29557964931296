import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import "./styles.scss";
const Layout = ({ children }) => {
  const { pathname } = useLocation();
  return (
    <div className="relative layout radial-gradient-top w-full z-[100]">
      <div className="flex flex-col w-full block">
        <Header />
        <div
          className={`${
            pathname == "/" ? "pt-0" : "md:pt-[158px] pt-[138px]"
          } z-[200] w-full mx-auto layout-height`}
        >
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default Layout;
