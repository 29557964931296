import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Drawer, Dropdown, Menu } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import Logo from "../../../assets/svg/Logo";
import "./styles.scss";

const Header = () => {
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState({});
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const getPageData = async () => {
    setLoading(true);
    try {
      let config = {
        method: "get",

        url: `${process.env.REACT_APP_STRAPI_URL}/api/fundheaders/1`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
        params: {
          populate: {
            links: {
              populate: ["title"],
            },
            Logo: {
              populate: ["image"],
            },
            button: {
              populate: ["title"],
            },
          },
        },
      };

      const res = await axios.request(config);
      if (res?.data?.data?.id && res?.data?.data?.attributes) {
        setPageData(res.data.data.attributes);
        const menuItems = res.data.data.attributes?.links?.map(
          (item, index) => ({
            key: item.path,
            label: <Link to={item.path}>{item.title}</Link>,
          })
        );
        const menuButton = {
          key: "get-a-quote",
          label: (
            <Link
              to={res.data.data.attributes?.button?.link}
              className="rounded-lg text-[14px] font-medium bg-[#3364F6] text-[#FFFFFF] px-4 py-2"
            >
              <span className="text-[#FFFFFF]">
                {res.data.data.attributes?.button?.text ?? "Get a -quote"}
              </span>
            </Link>
          ),
        };
        setMenu({ items: [...menuItems, menuButton] });
      }
    } catch (error) {
      console.log("error in fetch blogs", error);
    }
    setLoading(false);
    return true;
  };
  const handlePageNavigation = (link, target) => {
    if (link) {
      if (target) {
        window.location.href = link;
      } else {
        navigate(`${link}`, { state: "focus" });
      }
    }
  };
  useEffect(() => {
    getPageData();
  }, []);

  return (
    <div
      className={`fixed top-0 right-0 left-0 z-[201] py-[8px] bg-white ${
        pathname == "/" ? "headerTop" : "shadow"
      }`}
    >
      <nav className="flex justify-between items-center px-5 md:px-16 py-4">
        <div
          className="cursor-pointer flex items-end"
          onClick={() => {
            navigate("/");
          }}
        >
          <Logo />
          <span className="ml-1 font-bold text-lg sm:text-xl md:text-2xl text-[#1D2939]">
            Blu Hat Funding
          </span>
        </div>
        <div className="hidden lg:flex lg:gap-6">
          {pageData?.links?.length > 0 &&
            pageData?.links?.map((item, index) => (
              <Link
                to={item.path}
                className={`${
                  pathname === item.path ? "text-[#3364F6]" : "text-[#2F2B4399]"
                } hover:text-[#3364F6] font-medium text-[16px]`}
              >
                {item.title}
              </Link>
            ))}
        </div>
        <div>
          {pageData?.button?.id && (
            <Button
              onClick={() => {
                handlePageNavigation(
                  pageData.button.link,
                  pageData.button.targetBlank
                );
              }}
              type="primary"
              className="hidden md:block md:px-5 rounded-lg text-[14px] font-medium"
            >
              {pageData.button.text ?? "Get a quote"}
            </Button>
          )}
        </div>
        <div className="lg:hidden">
          {menu?.items?.length > 0 && (
            <Dropdown menu={menu} trigger={["click"]}>
              <Button
                type="default"
                className="block lg:hidden border-none"
                icon={<MenuOutlined style={{ color: "#2F2B43" }} />}
              />
            </Dropdown>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Header;
