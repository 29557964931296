const XSvg = () => {
  return (
    <svg width='37' height='38' viewBox='0 0 37 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M33.9167 0H3.08333C2.26558 0 1.48132 0.31607 0.903087 0.87868C0.32485 1.44129 0 2.20435 0 3V33C0 33.7957 0.32485 34.5587 0.903087 35.1213C1.48132 35.6839 2.26558 36 3.08333 36H33.9167C34.7344 36 35.5187 35.6839 36.0969 35.1213C36.6751 34.5587 37 33.7957 37 33V3C37 2.20435 36.6751 1.44129 36.0969 0.87868C35.5187 0.31607 34.7344 0 33.9167 0ZM28.8407 25.9387C28.984 26.0781 29.0976 26.2436 29.1751 26.4257C29.2526 26.6077 29.2925 26.8029 29.2925 27C29.2925 27.1971 29.2526 27.3923 29.1751 27.5743C29.0976 27.7564 28.984 27.9219 28.8407 28.0613C28.6975 28.2006 28.5274 28.3112 28.3403 28.3866C28.1532 28.462 27.9526 28.5008 27.75 28.5008C27.5474 28.5008 27.3468 28.462 27.1597 28.3866C26.9726 28.3112 26.8025 28.2006 26.6593 28.0613L18.5 20.1206L10.3407 28.0613C10.0514 28.3427 9.6591 28.5008 9.25 28.5008C8.8409 28.5008 8.44855 28.3427 8.15927 28.0613C7.86999 27.7798 7.70748 27.398 7.70748 27C7.70748 26.602 7.86999 26.2202 8.15927 25.9387L16.3205 18L8.15927 10.0613C7.86999 9.77979 7.70748 9.39805 7.70748 9C7.70748 8.60195 7.86999 8.22021 8.15927 7.93875C8.44855 7.65729 8.8409 7.49917 9.25 7.49917C9.6591 7.49917 10.0514 7.65729 10.3407 7.93875L18.5 15.8794L26.6593 7.93875C26.9485 7.65729 27.3409 7.49917 27.75 7.49917C28.1591 7.49917 28.5514 7.65729 28.8407 7.93875C29.13 8.22021 29.2925 8.60195 29.2925 9C29.2925 9.39805 29.13 9.77979 28.8407 10.0613L20.6795 18L28.8407 25.9387Z'
        fill='black'
      />
    </svg>
  )
}
export default XSvg
