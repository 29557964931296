import { useEffect } from 'react'

const BookACall = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])
  return (
    <div>
      <iframe
        src='https://calendly.com/bluhatbookkeeping/15min_consultation_bookkeeping_services'
        width='100%'
        height='1200px'
        style={{ border: 'none' }}
        title='Book A Call'
      ></iframe>
    </div>
  )
}
export default BookACall