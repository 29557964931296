import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import axios from "axios";
import "./styles.scss";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LinkedinSvg from "../../assets/svg/LinkedinSvg";
import FacebookSvg from "../../assets/svg/FacebookSvg";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import XSvg from "../../assets/svg/XSvg";
const Article = () => {
  const [loading, setLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [blog, setBlog] = useState({});
  const [blogs, setBlogs] = useState([]);
  const [activeCategories, setActiveCategories] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const getBlog = async (slug) => {
    try {
      let config = {
        params: {
          filters: {
            slug: {
              $eq: slug,
            },
          },
          populate: {
            cover: {
              fields: ["name", "url"],
            },
            author: {
              populate: ["avatar"],
            },
            article_category: {
              populate: ["name"],
            },
          },
        },
        method: "get",
        url: `${process.env.REACT_APP_STRAPI_URL}/api/fund-articles?populate=*`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
      };

      const res = await axios.request(config);
      if (res?.status == 200 && res?.data?.data?.length) {
        let tempList = [];
        
        if (
          res.data.data[0]?.attributes?.article_category?.data?.attributes?.slug
        ) {
          tempList.push(
            res.data.data[0]?.attributes?.article_category?.data?.attributes
              ?.slug,
          );
        }

        setActiveCategories(tempList);
        setBlog(res.data.data[0]);
      }
    } catch (error) {
      console.log("error in fetch blogs", error);
    }
    return true;
  };
  const getBlogsByCategory = async () => {
    try {
      let config = {
        method: "get",

        url: `${process.env.REACT_APP_STRAPI_URL}/api/fund-articles`,
        params: {
          pagination: {
            pageSize: 2,
            page: 1,
          },
          populate: {
            cover: {
              fields: ["name", "url"],
            },
            author: {
              populate: ["avatar"],
            },
            categories: {
              populate: ["name"],
            },
          },
        },
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
      };

      const res = await axios.request(config);
      if (res?.status == 200 && res?.data?.data?.length) {
        setBlogs(res.data.data);
      }
    } catch (error) {
      console.log("error in fetch blogs", error);
    }
    return true;
  };
  const getCategories = async (slug) => {
    setCategoriesLoading(true);
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_STRAPI_URL}/api/article-categories`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
        params: {
          populate: {
            fund_articles: {
              populate: ["title"],
            },
          },
        },
      };

      const res = await axios.request(config);
      if (res?.status == 200 && res?.data?.data?.length) {
        let tempCtg = [];
        res.data.data.map((item) => {
          if (item?.attributes?.fund_articles?.data?.length > 0) {
            tempCtg.push(item);
          }
        });
        setCategories(tempCtg);
      }
    } catch (error) {
      console.log("error in fetch blogs", error);
    }
    setCategoriesLoading(false);
    return true;
  };

  useEffect(() => {
    const fetchData = async (slug) => {
      setLoading(true);
      await getBlog(slug);
      await getBlogsByCategory();
      setLoading(false);
    };
    if (params?.slug) {
      fetchData(params.slug);
    }
  }, [params]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getCategories();
  }, []);
  const getCroppedImageStyle = (imageSrc) => {
    const aspectRatio = 16 / 9;
    const modifiedSrc = `${imageSrc}`;
    const width = 100;
    const height = 400 / aspectRatio;
    return {
      backgroundImage: `url(${modifiedSrc})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: `${width}%`,
      height: `${height}px`,
    };
  };
  const navigateToBlog = (slug) => {
    navigate(`/article/${slug}`);
  };
  const navigateToCategory = (slug) => {
    navigate(`/article/category/${slug}`);
  };
  return (
    <>
      <div className="w-full flex flex-col">
        {loading ? (
          <></>
        ) : (
          <div className="w-full flex flex-col items-center justify-center">
            {blog.id && (
              <>
                <div className="h-full w-5/6">
                  <section class="mysection">
                    {blog?.attributes?.cover?.data?.attributes?.url && (
                      <img
                        src={`${blog.attributes?.cover?.data?.attributes?.url}`}
                      />
                    )}
                    <div class="content">
                      <div className="flex flex-wrap mb-[10px] lg:mb-[20px]">
                        {blog.attributes?.categories?.data?.length > 0 &&
                          blog.attributes?.categories?.data?.map((category) => {
                            return (
                              <div
                                className="bg-black text-white px-2 lg:px-4 py-1 lg:py-2 rounded-[5px] lg:rounded-[10px] mr-1 lg:mr-2 my-1 font-medium text-base lg:text-xl cursor-pointer"
                                onClick={() => {
                                  navigate(
                                    `/article/category/${category.attributes?.slug}`
                                  );
                                }}
                              >
                                {category.attributes?.name}
                              </div>
                            );
                          })}
                      </div>
                      {blog.attributes?.title && (
                        <h1 className="font-bold text-2xl lg:text-4xl">
                          {blog.attributes?.title}
                        </h1>
                      )}
                      {blog.attributes?.description && (
                        <p className="text-lg lg:text-[26px] text-[#667085] mt-[10px] lg:mt-[20px]">
                          {blog.attributes?.description}
                        </p>
                      )}
                      {blog.attributes?.author?.data?.attributes?.name && (
                        <div className="flex justify-start items-end mt-[10px] lg:mt-[25px]">
                          <span className="mr-1 lg:mr-2 text-[#1570EF] text-base lg:text-xl font-normal">
                            Written by{" "}
                          </span>
                          <span className="text-[#1570EF] text-base lg:text-xl font-normal">
                            {blog.attributes?.author?.data?.attributes?.name}
                          </span>
                        </div>
                      )}
                      {blog?.attributes?.Body && (
                        <div className="markdown text-base lg:text-[21px] text-[#667085]">
                          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                            {blog?.attributes?.Body}
                          </ReactMarkdown>
                        </div>
                      )}
                    </div>
                  </section>
                </div>
                <div className="flex justify-start items-center mb-[10px] lg:mb-[20px]">
                  <span className="font-normal text-base lg:text-xl text-[#667085] mr-[10px] lg:mr-[20px]">
                    Share on
                  </span>
                  <div className="flex items-end">
                    <span className="mx-2">
                      <FacebookShareButton
                        url={window.location.href}
                        quote={"Dummy text!"}
                        hashtag="#bluhat"
                      >
                        <FacebookSvg />
                      </FacebookShareButton>
                    </span>
                    <span className="mx-2">
                      <LinkedinShareButton
                        url={window.location.href}
                        quote={blog?.attributes?.title}
                        hashtag="#bluhat"
                      >
                        <LinkedinSvg />
                      </LinkedinShareButton>
                    </span>
                    <span className="mx-2">
                      <TwitterShareButton
                        url={window.location.href}
                        quote={blog?.attributes?.title}
                        hashtag="#bluhat"
                      >
                        <XSvg />
                      </TwitterShareButton>
                    </span>
                  </div>
                </div>
              </>
            )}
            <div className="my-[15px] lg:my-[30px]">
              <h1 className="font-semibold text-xl lg:text-[32px] text-[#1D2939] text-center">
                Related Posts
              </h1>
              <div className="w-full flex justify-center items-center">
                <div className="flex justify-center lg:justify-between flex-wrap w-5/6 blogs">
                  {blogs.map((item) => {
                    return (
                      <div
                        className="w-[98%] lg:w-[48%] h-full p-[13px] lg:p-[20px] mt-[20px] bg-white card-shadow rounded-xl"
                        key={item?.attributes?.slug}
                      >
                        <div className="w-full">
                          <div
                            className="flex mx-auto"
                            style={getCroppedImageStyle(
                              !item.attributes?.cover?.data
                                ? `${process.env.PUBLIC_URL}/dummyImage.jpg`
                                : item.attributes?.cover?.data?.attributes?.url
                            )}
                          ></div>
                        </div>
                        <div className="max-h-[32px] lg:max-h-[46px] h-[32px] lg:h-[46px] mt-[8px] lg:mt-[10px] flex justify-start items-center flex-wrap overflow-hidden">
                          {item?.attributes?.categories?.data?.length > 0 &&
                            item.attributes.categories.data?.map((category) => {
                              return (
                                <span
                                  onClick={() => {
                                    navigateToCategory(
                                      category?.attributes?.slug
                                    );
                                  }}
                                  key={category?.attributes?.slug}
                                  className="bg-black text-white px-2 lg:px-4 py-1 lg:py-2 rounded-[5px] lg:rounded-[10px] mr-1 lg:mr-2 font-semibold text-base lg:text-xl cursor-pointer"
                                >
                                  {category?.attributes?.name}
                                </span>
                              );
                            })}
                        </div>
                        <div className="max-h-[56px] lg:max-h-[72px] h-[56px] lg:h-[72px] mt-[8px] lg:mt-[10px]">
                          <h1 className="card-title font-semibold text-lg lg:text-2xl text-[#101828]">
                            {item?.attributes?.title}
                          </h1>
                        </div>

                        <div className="flex items-center font-semibold text-[15px] text-[#3C87F1] max-h-[23px] h-[23px] mt-[10px]">
                          {item?.attributes?.author?.data?.attributes?.name && (
                            <>
                              <span className="mr-2">By</span>{" "}
                              <h1 className="author-title">
                                {
                                  item?.attributes?.author?.data?.attributes
                                    ?.name
                                }
                              </h1>
                            </>
                          )}
                        </div>
                        <div className="description max-h-[97px] h-[97px] mt-[7px] lg:mt-[10px]">
                          {item?.attributes?.description && (
                            <p className="font-semibold text-[#667085] text-sm lg:text-base">
                              {item?.attributes?.description}
                            </p>
                          )}
                        </div>
                        <div className="flex justify-center mt-[5px] lg:mt-[10px]">
                          <button
                            onClick={() => {
                              navigateToBlog(item?.attributes?.slug);
                            }}
                            className="w-full bg-[#3364F6] rounded-[12px] py-[12px] px-[16px] text-[white] font-medium text-[16px] cursor-pointerx"
                          >
                            Read More
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        {categoriesLoading ? (
          <></>
        ) : (
          <div className="w-full flex flex-col items-center justify-center">
            <div className="my-[25px] lg:my-[50px] w-5/6">
              <h1 className="text-center font-semibold text-xl lg:text-3xl mb-[10px] lg:mb-[20px]">
                Explore More Topics
              </h1>
              <div className="flex flex-wrap">
                {categories?.length > 0 &&
                  categories?.map((category) => {
                    return (
                      <span
                        onClick={() => {
                          navigateToCategory(category?.attributes?.slug);
                        }}
                        className={`${
                          activeCategories?.includes(category?.attributes?.slug)
                            ? "bg-black text-white"
                            : "bg-white text-[#475467]"
                        }  cursor-pointer font-semibold text-base rounded-md lg:rounded-xl px-2 lg:px-4 py-2 lg:py-3 mr-2 lg:mr-5 my-1 lg:my-2`}
                        style={{ border: "2px solid #1D2939" }}
                        key={`category-${category?.attributes?.name}`}
                      >
                        {category?.attributes?.name}
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Article;
