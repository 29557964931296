const ArrowDown = () => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.33378 0.488037L8.00045 7.1547L14.6671 0.488037L15.8456 1.66655L8.58971 8.92247C8.26427 9.24791 7.73663 9.24791 7.4112 8.92247L0.155273 1.66655L1.33378 0.488037Z"
        fill="#344054"
      />
    </svg>
  );
};

export default ArrowDown;
