const Logo = () => {
  return (
    <svg width="40" height="38" viewBox="0 0 40 38" fill="none">
      <path
        d="M23.6941 7.3793L20.3848 4.95588L10.684 11.953L7.20604 14.3849L2.0092 17.7483C1.57455 18.0366 0.915261 18.6362 0.355625 18.3001C-0.0875363 18.0342 0.00231404 17.6001 0.0178441 17.1668L0.0245018 14.8864C0.0104508 13.578 0.0912439 13.7968 1.17761 13.0447L12.0461 5.4695L19.5195 0.312347C19.7584 0.160255 19.9777 0.0499403 20.2598 0.00307789C20.9032 -0.0459795 21.4582 0.459155 21.9489 0.800526L31.2223 7.32753C31.3201 7.39558 31.9635 7.80274 31.9961 7.84097L32.0282 4.00674C32.0284 3.50757 31.9925 2.97438 32.0436 2.47941C32.1687 1.26163 33.4686 1.48418 34.3215 1.48287L36.624 1.48329C37.6285 1.50959 37.796 2.25388 37.7973 3.0373L37.7999 11.9949C37.8004 12.0652 37.7803 12.2616 37.8037 12.3198C37.8686 12.48 39.5634 13.397 39.965 13.93C40.0025 14.1813 39.9767 14.4679 39.9763 14.7231L39.9813 17.075C39.9883 17.2965 40.0275 17.5648 39.9657 17.7783C39.76 18.4891 39.0412 18.4156 38.5409 18.0355L28.7164 10.9794L23.6941 7.3793Z"
        fill="#3364F6"
      />
      <path
        d="M34.8756 37.9966L3.27239 38.0001C2.03253 37.9886 1.98983 37.1111 1.99888 36.0841L1.99906 22.2865C1.98929 20.6151 2.09457 20.2992 3.40843 19.4048L7.82285 16.3349C8.543 15.835 9.61644 14.8993 10.5017 14.8041C11.4089 14.7389 12.2204 15.5283 12.9261 16.0014L17.848 19.3143C18.3566 19.6518 18.8262 20.0179 19.4066 20.2174L19.4795 20.2422C20.4567 20.5459 21.258 20.0864 22.0115 19.515L26.6352 16.3172C27.2819 15.8833 27.9264 15.3918 28.6124 15.0263C29.6334 14.4823 30.3211 15.1398 31.1467 15.7042L35.1341 18.4791C35.8052 18.9472 36.5509 19.3687 37.174 19.9015C38.1883 20.7679 37.9805 22.0991 37.9805 23.2993L37.9785 36.5926C37.9776 36.7577 37.991 36.9511 37.9363 37.1088L37.9277 37.1337C37.7761 37.5997 37.3649 37.972 36.8729 37.9925L34.8756 37.9966ZM8.59582 21.5401C7.51225 21.6603 6.55349 22.5719 6.35396 23.6696C6.27817 24.0872 6.31779 24.5662 6.31797 24.9909L6.32104 29.7701C6.32267 31.2006 6.28106 32.3948 7.51243 33.4036C8.38037 34.1147 8.94658 34.0401 9.96738 34.0405L29.7253 34.0377C31.0219 34.0364 32.099 34.0862 32.9923 32.9338L33.1241 32.7555C33.7482 31.7472 33.6583 31.0658 33.659 29.9338L33.6607 24.9573C33.6607 24.6088 33.7041 24.1985 33.6341 23.86L33.6153 23.7807C33.2694 22.3369 31.9988 21.3399 30.5357 21.6997L20.9511 24.4642C20.1803 24.6679 19.9947 24.6985 19.2181 24.51L13.9815 23.0921L9.12403 21.603C8.95562 21.558 8.77056 21.5225 8.59582 21.5401Z"
        fill="#3364F6"
      />
      <path
        d="M27.2795 31.9884L13.5553 31.9865C12.9928 31.9863 12.4055 32.0209 11.8466 31.9764C10.0302 31.8326 9.90521 29.2283 11.5696 28.8013C11.8184 28.7374 12.0855 28.7404 12.3409 28.7378L13.3129 28.7303C13.3884 28.6386 13.4119 28.3006 13.441 28.171L14.1347 24.7992L19.3755 26.2504C20.2635 26.4417 20.4264 26.3348 21.2791 26.0959L24.6675 25.1858C25.0175 25.093 25.3718 25.0093 25.7158 24.8946L26.5028 28.2531C26.5411 28.3994 26.5568 28.5568 26.6166 28.6954C27.0371 28.6975 27.4647 28.6842 27.8841 28.7145C30.362 28.8934 29.953 31.9624 27.9373 31.985L27.2795 31.9884Z"
        fill="#3364F6"
      />
    </svg>
  );
};
export default Logo;
