import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  const [pageData, setPageData] = useState({});

  const getCroppedImageStyle = (imageSrc, imgHeight) => {
    const aspectRatio = 1 / 1.1;
    const modifiedSrc = `${imageSrc}`;
    const width = "100%";
    const height = imgHeight / aspectRatio;
    return {
      backgroundImage: `url(${modifiedSrc})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: `${width}%`,
      height: `${height}px`,
    };
  };
  const getCroppedImageStyleMobile = (imageSrc, imgHeight) => {
    const aspectRatio = 1 / 1.1;
    const modifiedSrc = `${imageSrc}`;
    const width = "100%";
    const height = imgHeight / aspectRatio;
    return {
      backgroundImage: `url(${modifiedSrc})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: `${width}%`,
      height: `${height}px`,
    };
  };

  const getPageData = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_STRAPI_URL}/api/page-not-founds/1`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
        params: {
          populate: {
            button: {
              populate: ["title", "link"],
            },
            image: {
              populate: ["image"],
            },
          },
        },
      });

      if (response?.status === 200 && response?.data?.data?.attributes?.publishedAt!=null) {
        setPageData(response.data.data.attributes);
      }
    } catch (error) {
      console.log("Error:~", error.response);
      return;
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getPageData();
  }, []);

  return (
    <div className="w-full flex flex-col md:flex-row gap-20 justify-between py-24 md:py-0">
      <div className="w-full md:w-[50%] flex flex-col justify-center items-start px-5 md:px-[120px]">
        {pageData?.title && (
          <h1 className="font-semibold text-[36px] text-[#101828] md:text-[52px] mb-[24px]">
            {pageData.title}
          </h1>
        )}
        {pageData?.description && (
          <p className="font-normal text-[18px] md:text-[20px] text-[#667085] mb-[48px]">
            {pageData.description}
          </p>
        )}
        {pageData?.button?.id && (
          <button
            className="bg-[#3364f6] w-full md:w-[150px] font-medium text-[16px] text-[#ffffff] text-center rounded-[12px] py-[12px] px-[16px]"
            onClick={() => navigate(pageData.button.link)}
          >
            {pageData.button.title}
          </button>
        )}
      </div>
      {pageData?.image?.data?.attributes?.url&&<>
      <div className="w-full md:w-[50%] px-5 md:px-0 hidden md:block">
        <div
          style={getCroppedImageStyle(
            `${pageData.image.data.attributes.url}`,
            800
          )}
        ></div>
      </div>
      <div className="w-full md:w-[50%] px-5 md:px-0 md:hidden block">
        <div
          style={getCroppedImageStyle(
            `${pageData.image.data.attributes.url}`,
            400
          )}
        ></div>
      </div>
      </>}
    </div>
  );
};
export default PageNotFound;
