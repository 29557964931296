import { useEffect, useRef, useState } from "react";
import axios from "axios";
import moment from "moment";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useForm } from "sunflower-antd";
import {
  Input,
  Button,
  Form,
  Select,
  AutoComplete,
  InputNumber,
  Radio,
  DatePicker,
  Switch,
  Upload,
  message,
  notification,
  Steps,
  Progress,
  Popover,
} from "antd";
import { useNavigate } from "react-router-dom";
import usStates from "../../assets/global/usStates";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import TextArea from "antd/es/input/TextArea";
import "./styles.scss";
import ArrowUp from "../../assets/svg/ArrowUp";
import ArrowDown from "../../assets/svg/ArrowDown";

const { Option } = Select;
const { Step } = Steps;

const stepOptions = [
  {
    title: "1",
    description: "Personal Information",
  },
  {
    title: "2",
    description: "Property Information",
  },
  {
    title: "3",
    description: "Type of Property",
  },
  {
    title: "4",
    description: "Note Information",
  },
  {
    title: "5",
    description: "Additional Information",
  },
];

const sourceOptions = [
  {
    value: 1,
    text: "Internet Search",
  },
  {
    value: 2,
    text: "Letter",
  },
  { value: 3, text: "Postcard" },
  { value: 4, text: "Online Ad" },
  { value: 5, text: "Networking Event" },
  { value: 6, text: "Referral" },
  { value: 7, text: "Other" },
];
const typeOfPropertyOptions = [
  {
    value: 1,
    text: "Single Family Detached Home",
  },
  {
    value: 2,
    text: "Double Wide Mobile Home with Land",
  },
  { value: 3, text: "Single Wide Mobile Home with Land" },
  { value: 4, text: "Mobile Home Only – No Land" },
  { value: 5, text: "Condo" },
  { value: 6, text: "Townhouse" },
  { value: 7, text: "2-4 Residential Units" },
  { value: 8, text: "5+ Residential Units" },
  { value: 9, text: "Improved Residential Lot" },
  { value: 10, text: "Improved Commercial Lot" },
  { value: 11, text: "Improved Land" },
  { value: 12, text: "Agricultural Land" },
  { value: 13, text: "Raw Land (No Improvements)" },
  { value: 14, text: "Commercial Real Estate" },
  { value: 15, text: "Business Only – No Real Estate" },
  { value: 16, text: "Other" },
];

const acreageOptions = [
  {
    value: 1,
    text: "Less Than 1 Acre",
  },
  {
    value: 2,
    text: "1 to 5 Acres",
  },
  { value: 3, text: "6 to 10 Acres" },
  { value: 4, text: "11 to 20 Acres" },
  { value: 5, text: "More Than 20 Acres" },
];
const occupancyOptions = [
  {
    value: 1,
    text: "Owner Occupied",
  },
  {
    value: 2,
    text: "Rental",
  },
  { value: 3, text: "Vacational" },
];

const paymentScheduleOptions = [
  {
    value: 1,
    text: "Weekly",
  },
  {
    value: 2,
    text: "Monthly",
  },
  {
    value: 3,
    text: "Quarterly",
  },
  {
    value: 4,
    text: "Semi-Anually",
  },
  {
    value: 5,
    text: "Anually",
  },
];

const ballonPaymentDateOptions = [
  {
    value: 1,
    text: "Yes",
  },
  {
    value: 2,
    text: "No",
  },
];

const paymentsCurrentOptions = [
  {
    value: 1,
    text: "Yes - Current",
  },
  {
    value: 2,
    text: "No - Delinquent",
  },
];

const whoCollectsOptions = [
  {
    value: 1,
    text: "Seller Collects Directly From Buyer/Payer",
  },
  {
    value: 2,
    text: "Third Party Servicer",
  },
];

const firstPositionNotesOptions = [
  {
    value: 1,
    text: "Yes",
  },
  {
    value: 2,
    text: "No",
  },
];

const copiesOfDocsOptions = [
  {
    value: 1,
    text: "Yes – Copies Included in Attached Upload",
  },
  {
    value: 2,
    text: "Yes – By Fax (818-330-1101)",
  },
  {
    value: 3,
    text: "No – Documents Will Follow At A Later Date",
  },
];
const QuoteForm = ({ pageData }) => {
  const navigate = useNavigate();
  const [isDownPaymentNumeric, setIsDownPaymentNumeric] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState("");
  const [step, setStep] = useState(1);
  const [showStepInfo, setShowStepInfo] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState({
    step_1: true,
    step_2: true,
    step_3: true,
    step_4: true,
    step_5: true,
  });
  const [loading, setLoading] = useState(false);

  const [fileId, setFileId] = useState();
  const [fileUploading, setFileUploading] = useState(false);

  const [uploadingData, setUploadingData] = useState(false);
  const [form] = Form.useForm();
  const { formProps, formValues, formResult, formLoading, submit } = useForm({
    form,
    async submit(formValues) {
      await new Promise((r) => setTimeout(r, 1000));
      return "ok";
    },
  });
  const [validationTrigger, setValidationTrigger] = useState({
    personalInformation: {
      firstName: ["onBlur"],
      lastName: ["onBlur"],
      email: ["onBlur"],
      phoneNumber: ["onBlur"],
      source: ["onBlur"],
    },
    propertyInformation: {
      address: ["onBlur"],
      city: ["onBlur"],
      state: ["onBlur"],
      zipCode: ["onBlur"],
    },
    typeOfProperty: {
      typeOfProperty: ["onBlur"],
      acreage: ["onBlur"],
      occupancy: ["onBlur"],
      propertyDescription: ["onBlur"],
      currentEstimatedValue: ["onBlur"],
    },
    noteInformation: {
      saleDate: ["onBlur"],
      propertySalePrice: ["onBlur"],
      isDownPaymentNumeric: ["onBlur"],
      downPaymentAmountNumeric: ["onBlur"],
      downPaymentAmountPercentage: ["onBlur"],
      originalStartingBalance: ["onBlur"],
      currentPrincipalBalance: ["onBlur"],
      interestRate: ["onBlur"],
      principalAndInterestPayment: ["onBlur"],
      paymentSchedule: ["onBlur"],
      nextPaymentDueDate: ["onBlur"],
      ballonPayment: ["onBlur"],
      arePaymentsCurrent: ["onBlur"],
      whoCollectsPayment: ["onBlur"],
      isFirstPositionNote: ["onBlur"],
    },
    additionalInformation: {
      reasonsForSelling: ["onBlur"],
      copyOfDocs: ["onBlur"],
      isCopyOfDocProviding: ["onBlur"],
      additionalComments: ["onBlur"],
    },
  });

  const stepRef = useRef();

  const TwoDecimalRegex = /^\d+(\.\d{1,2})?$/;

  // Effects
  useEffect(() => {
    if (stepRef.current && step !== 1) {
      stepRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [step]);

  //Start Methods
  const handlePhoneNumberChange = (value) => {
    setValue(value);
  };
  const validatePhoneNumber = (number) => {
    const isValid = isValidPhoneNumber(`+${number}`);
    return isValid;
  };

  const formatInput = (value) => {
    const numericValue = value.replace(/[^0-9.]/g, "");
    // Format with commas for thousands
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const amountValidation = (_, value) => {
    if (value > 99000000) {
      return Promise.reject(new Error("We cannot process this much amount"));
    }
    if (value && !TwoDecimalRegex.test(value)) {
      return Promise.reject(
        new Error("Value can have a maximum of two decimal places")
      );
    }
    return Promise.resolve();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleFieldsChange = (_, allFields) => {
    if (_?.length) {
      const currentObj = _[0];
      if (
        currentObj?.validated &&
        currentObj?.errors?.length &&
        currentObj?.name?.length
      ) {
        const main = currentObj.name[0];
        const sec = currentObj.name[1];
        const validator = validationTrigger;
        if (!validator[`${main}`][`${sec}`].includes("onChange")) {
          validator[`${main}`][`${sec}`].push("onChange");
          setValidationTrigger({
            personalInformation: validator.personalInformation,
            propertyInformation: validator.propertyInformation,
            typeOfProperty: validator.typeOfProperty,
            noteInformation: validator.noteInformation,
            additionalInformation: validator.additionalInformation,
          });
        }
      }
    }
  };
  const handleValuesChange = (data, allFields) => {
    if (allFields?.noteInformation?.isDownPaymentNumeric) {
      setIsDownPaymentNumeric(true);
    } else {
      setIsDownPaymentNumeric(false);
    }
    let step_1 = true;
    let step_2 = true;
    let step_3 = true;
    let step_4 = true;
    let step_5 = true;

    try {
      if (
        allFields?.personalInformation?.phoneNumber &&
        allFields?.personalInformation?.firstName &&
        allFields?.personalInformation?.lastName &&
        allFields?.personalInformation?.email &&
        allFields?.personalInformation?.source &&
        validatePhoneNumber(allFields?.personalInformation?.phoneNumber)
      ) {
        step_1 = false;
      } else {
        step_1 = true;
      }
    } catch (error) {
      step_1 = true;
    }
    try {
      if (
        allFields?.propertyInformation?.address &&
        allFields?.propertyInformation?.city &&
        allFields?.propertyInformation?.state &&
        allFields?.propertyInformation?.zipCode &&
        validateZipCodeValue(allFields?.propertyInformation?.zipCode)
      ) {
        step_2 = false;
      } else {
        step_2 = true;
      }
    } catch (error) {
      step_2 = true;
    }
    try {
      if (
        allFields?.typeOfProperty?.typeOfProperty &&
        allFields?.typeOfProperty?.occupancy &&
        allFields?.typeOfProperty?.propertyDescription &&
        allFields?.typeOfProperty?.currentEstimatedValue > 0 &&
        allFields?.typeOfProperty?.currentEstimatedValue < 99000000 &&
        TwoDecimalRegex.test(allFields?.typeOfProperty?.currentEstimatedValue)
      ) {
        step_3 = false;
      } else {
        step_3 = true;
      }
    } catch (error) {
      step_3 = true;
    }
    try {
      if (
        moment(allFields?.noteInformation?.saleDate).isValid() &&
        allFields?.noteInformation?.propertySalePrice > 0 &&
        allFields?.noteInformation?.propertySalePrice < 99000000 &&
        TwoDecimalRegex.test(allFields?.noteInformation?.propertySalePrice) &&
        allFields?.noteInformation?.isDownPaymentNumeric?.toString() &&
        ((allFields?.noteInformation?.downPaymentAmountNumeric > 0 &&
          allFields?.noteInformation?.downPaymentAmountNumeric < 99000000 &&
          TwoDecimalRegex.test(
            allFields?.noteInformation?.downPaymentAmountNumeric
          )) ||
          allFields?.noteInformation?.downPaymentAmountPercentage > 0) &&
        allFields?.noteInformation?.originalStartingBalance > 0 &&
        allFields?.noteInformation?.originalStartingBalance < 99000000 &&
        TwoDecimalRegex.test(
          allFields?.noteInformation?.originalStartingBalance
        ) &&
        allFields?.noteInformation?.currentPrincipalBalance > 0 &&
        allFields?.noteInformation?.currentPrincipalBalance < 99000000 &&
        TwoDecimalRegex.test(
          allFields?.noteInformation?.currentPrincipalBalance
        ) &&
        allFields?.noteInformation?.interestRate > 0 &&
        allFields?.noteInformation?.principalAndInterestPayment > 0 &&
        allFields?.noteInformation?.principalAndInterestPayment < 99000000 &&
        TwoDecimalRegex.test(
          allFields?.noteInformation?.principalAndInterestPayment
        ) &&
        allFields?.noteInformation?.paymentSchedule &&
        moment(allFields?.noteInformation?.nextPaymentDueDate).isValid() &&
        allFields?.noteInformation?.ballonPayment &&
        allFields?.noteInformation?.arePaymentsCurrent &&
        allFields?.noteInformation?.whoCollectsPayment &&
        allFields?.noteInformation?.isFirstPositionNote
      ) {
        step_4 = false;
      } else {
        step_4 = true;
      }
    } catch (error) {
      step_4 = true;
    }
    try {
      if (
        allFields?.additionalInformation?.reasonsForSelling &&
        allFields?.additionalInformation?.copyOfDocs &&
        allFields?.additionalInformation?.isCopyOfDocProviding &&
        allFields?.additionalInformation?.additionalComments
      ) {
        step_5 = false;
      } else {
        step_5 = true;
      }
    } catch (error) {
      step_5 = true;
    }

    setIsButtonDisabled({
      step_1,
      step_2,
      step_3,
      step_4,
      step_5,
    });
  };
  const validateZipCode = (rule, value) => {
    const zipCodeRegex = /^\d{5}$/;

    if (!zipCodeRegex.test(value)) {
      return Promise.reject(
        "Please enter a 5-digit zip code in number format (e.g., 23300)."
      );
    }

    return Promise.resolve();
  };
  const validateZipCodeValue = (value) => {
    try {
      const zipCodeRegex = /^\d{5}$/;

      if (!zipCodeRegex.test(value)) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const disabledFutureDates = (current) => {
    return current && current > moment().endOf("day");
  };

  const disabledPastDates = (current) => {
    return current && current < moment().startOf("day");
  };

  const beforeUpload = async (file) => {
    const isImage = file.type === "image/jpg" || file.type === "image/png";
    // file.type === "application/pdf";
    const isLt10MB = file.size / 1024 / 1024 < 10;

    if (!isImage) {
      message.error("You can only upload JPG, PNG or PDF files!");
      return Upload.LIST_IGNORE;
    }

    if (!isLt10MB) {
      message.error("File must be smaller than 10MB!");
      return Upload.LIST_IGNORE;
    }

    if (file && isImage && isLt10MB) {
      setFileUploading(true);
      delete file?.uid;
      const authorization = await authenticate();
      if (!authorization) {
        message.error("couldn't upload file...");
        setFileUploading(false);
        return Upload.LIST_IGNORE;
      }
      const uploadFIle = await uploadImageToPodio(
        authorization?.access_token,
        file
      );
      if (!uploadFIle) {
        message.error("couldn't upload file...");
        setFileUploading(false);
        return Upload.LIST_IGNORE;
      }
      setFileId(uploadFIle?.file_id);
      setFileUploading(false);
    }
    return false;
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const authenticate = async () => {
    try {
      const response = await axios({
        url: "https://api.podio.com/oauth/token/v2",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          grant_type: "password",
          username: process.env.REACT_APP_PODIO_ADMIN_USERNAME,
          password: process.env.REACT_APP_PODIO_ADMIN_PASSWORD,
          client_id: process.env.REACT_APP_QUOTE_REQUESTS_APP_CLIENT_ID,
          redirect_uri: process.env.REACT_APP_PODIO_REDIRECT_URI,
          client_secret: process.env.REACT_APP_QUOTE_REQUESTS_APP_CLIENT_SECRET,
        },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log("Error", error.response.data);
      return;
    }
  };

  const uploadImageToPodio = async (accessToken, file) => {
    try {
      const formData = new FormData();
      formData.append("source", file);
      formData.append("filename", file.name);
      const response = await axios.post(
        "https://api.podio.com/file/",
        formData,
        {
          headers: {
            Authorization: `OAuth2 ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error.response);
      return;
    }
  };

  const storeDataInPodio = async (accessToken, values) => {
    try {
      const appId = process.env.REACT_APP_QUOTE_REQUESTS_APP_ID;
      const url = `https://api.podio.com/item/app/${appId}/`;
      const payload = {
        fields: {
          title: "Sell Mortgage Note Info",
          "first-name": values?.personalInformation?.firstName,
          "last-name": values?.personalInformation?.lastName,
          email: [{ type: "work", value: values?.personalInformation?.email }],
          phone: [
            { type: "work", value: values?.personalInformation?.phoneNumber },
          ],
          "how-did-you-hear-about-us": values?.personalInformation?.source,
          address: `${values?.propertyInformation?.address}${values?.propertyInformation?.city}${values?.propertyInformation?.state},${values?.propertyInformation?.zipCode}`,
          "type-of-property": values?.typeOfProperty?.typeOfProperty,
          acreage: values?.typeOfProperty?.acreage,
          occupancy: values?.typeOfProperty?.occupancy,
          "property-description": values?.typeOfProperty?.propertyDescription,
          "current-estimated-property-value": {
            value: values?.typeOfProperty?.currentEstimatedValue,
            currency: "USD",
          },
          "sale-datedate-of-note": {
            start: formatDateTime(
              values?.noteInformation?.saleDate?.toString()
            ),
          },
          "property-sale-price": {
            value: values?.noteInformation?.propertySalePrice,
            currency: "USD",
          },
          "down-payment-amount-type": values?.noteInformation
            ?.isDownPaymentNumeric
            ? 2
            : 1,
          "down-payment-amount": values?.noteInformation?.isDownPaymentNumeric
            ? values?.noteInformation?.downPaymentAmountNumeric
            : values?.noteInformation?.downPaymentAmountPercentage,
          "original-starting-balance-of-note": {
            value: values?.noteInformation?.originalStartingBalance,
            currency: "USD",
          },
          "current-principal-balance-of-note": {
            value: values?.noteInformation?.currentPrincipalBalance,
            currency: "USD",
          },
          "interest-rate": values?.noteInformation?.interestRate,
          "principal-interest-payment-amount": {
            value: values?.noteInformation?.principalAndInterestPayment,
            currency: "USD",
          },
          "next-payment-due-date": {
            start: formatDateTime(
              values?.noteInformation?.nextPaymentDueDate?.toString()
            ),
          },
          "is-there-an-all-due-or-balloon-payment-date":
            values?.noteInformation?.ballonPayment,
          "are-payments-current": values?.noteInformation?.arePaymentsCurrent,
          "who-collects-payments": values?.noteInformation?.whoCollectsPayment,
          "is-this-a-first-position-note":
            values?.noteInformation?.isFirstPositionNote,
          "reason-for-selling-the-note":
            values?.additionalInformation?.reasonsForSelling,
          "copy-of-documents": fileId,
          "are-you-providing-copy-of-documents-at-this-time":
            values?.additionalInformation?.isCopyOfDocProviding,
          "additional-comments":
            values?.additionalInformation?.additionalComments,
        },
      };

      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `OAuth2 ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error storing data in Podio:", error);
      return;
    }
  };

  const errorToast = () => {
    return notification.error({
      message: "Error",
      description:
        "There was an issue sending your data. Please try again later.",
    });
  };
  const successToast = () => {
    return notification.success({
      message: "Success",
      description: "Your information has been sent successfully.",
    });
  };

  const onFinish = async (values) => {
    try {
      setUploadingData(true);
      const authorization = await authenticate();
      if (!authorization) {
        setUploadingData(false);
        errorToast();
        return;
      }
      // const file = await uploadImageToPodio(
      //   authorization?.access_token,
      //   values?.additionalInformation?.copyOfDocs?.fileList[0]?.originFileObj
      // );
      // if (!file) {
      //   setUploadingData(false);
      //   errorToast();
      //   return;
      // }
      const data = await storeDataInPodio(
        authorization?.access_token,
        values
        // file?.file_id
      );
      if (!data) {
        setUploadingData(false);
        errorToast();
        return;
      }
      setUploadingData(false);
      successToast();
      setFormSubmitted(true);
    } catch (error) {
      console.log("Error", error);
      setUploadingData(false);
      errorToast();
    }
  };

  return (
    <div className="w-full md:w-5/6 flex justify-center items-center">
      <div className="bg-[#FCFCFD] border-2 rounded-none md:rounded-[16px] py-10 md:p-20 border-[#2F2B431A] w-full md:w-[996px]">
        <Form
          layout="inline"
          {...formProps}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onFieldsChange={handleFieldsChange}
          className="flex flex-col w-full"
          style={{ maxWidth: "100%", md: 900 }}
          onValuesChange={handleValuesChange}
          disabled={loading?.step_1}
          initialValues={{
            noteInformation: {
              isDownPaymentNumeric: true,
            },
            additionalInformation: {
              copyOfDocs: [],
            },
          }}
        >
          {!formSubmitted ? (
            <>
              <div className="w-full px-10 mb-4" ref={stepRef}>
                <Progress
                  percent={step * 20}
                  status="active"
                  className="block md:hidden"
                  showInfo={false}
                />
                <div className="flex justify-between items-center md:hidden mb-5">
                  <div>
                    {step}/{stepOptions?.length}{" "}
                    {stepOptions[step - 1]?.description}
                  </div>
                  <div onClick={() => setShowStepInfo(!showStepInfo)}>
                    {showStepInfo ? <ArrowUp /> : <ArrowDown />}
                  </div>
                </div>
                <div
                  className={`${showStepInfo ? "block" : "hidden"} md:block`}
                >
                  <Steps
                    current={step - 1}
                    direction="horizontal"
                    size="default"
                    items={stepOptions}
                    progressDot={true}
                  />
                </div>
              </div>
              {/* START Step 1 */}
              <div
                className={`${
                  step == 1 ? "block" : "hidden"
                } flex flex-col gap-5 px-3`}
              >
                <div className="font-bold text-[#344054] text-[18px]">
                  Personal Information
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-col items-start justify-between w-[45%]">
                    <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                      * First Name
                    </h1>
                    <Form.Item
                      name={["personalInformation", "firstName"]}
                      rules={[
                        {
                          required: true,
                          message: "First Name is required!",
                        },
                        {
                          min: 1,
                          message: "First Name should be minimum 1 letter!",
                        },
                      ]}
                      validateTrigger={
                        validationTrigger?.personalInformation?.firstName
                      }
                      className="w-full"
                    >
                      <Input placeholder="First Name" className="w-full" />
                    </Form.Item>
                  </div>
                  <div className="flex flex-col items-start justify-between w-[45%]">
                    <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                      Last Name
                    </h1>
                    <Form.Item
                      name={["personalInformation", "lastName"]}
                      validateTrigger={
                        validationTrigger?.personalInformation?.lastName
                      }
                      className="w-full"
                      rules={[
                        {
                          required: true,
                          message: "Last Name is required!",
                        },
                        {
                          min: 1,
                          message: "Last Name should be minimum 1 letter!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Last Name" className="w-full" />
                    </Form.Item>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-between">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    * Email
                  </h1>
                  <Form.Item
                    name={["personalInformation", "email"]}
                    validateTrigger={
                      validationTrigger?.personalInformation?.email
                    }
                    className="w-full"
                    rules={[
                      { type: "email" },
                      { required: true, message: "Please enter a valid email" },
                    ]}
                  >
                    <Input
                      placeholder="Enter Email Address"
                      className="w-full"
                    />
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Phone number
                  </h1>
                  <Form.Item
                    name={["personalInformation", "phoneNumber"]}
                    // label='Phone number'
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                    ]}
                    className="w-full"
                  >
                    <PhoneNumberInput
                      value={value}
                      onChange={handlePhoneNumberChange}
                    />
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    How did you hear about us?
                  </h1>
                  <Form.Item
                    name={["personalInformation", "source"]}
                    rules={[
                      {
                        required: true,
                        message: "Please Select an Option!",
                      },
                    ]}
                    className="w-full"
                    validateTrigger={
                      validationTrigger?.personalInformation?.source
                    }
                  >
                    <Select name="type" placeholder="Please Select an Option">
                      {sourceOptions?.map((option) => (
                        <Option value={option.value} key={option.text}>
                          {option.text}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="w-full flex justify-between items-center">
                  <Form.Item>
                    <Button
                      type="default"
                      className="w-full bg-[#2F2B430D]"
                      disabled={true}
                    >
                      Back
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      disabled={isButtonDisabled?.step_1}
                      className="w-full"
                      onClick={() => {
                        setStep(2);
                      }}
                    >
                      Next
                    </Button>
                  </Form.Item>
                </div>
              </div>
              {/* END Step 1 */}
              {/* START Step 2 */}
              <div
                className={`${
                  step == 2 ? "block" : "hidden"
                } flex flex-col gap-5 px-3`}
              >
                <div className="font-bold text-[#344054] text-[18px]">
                  Property Information
                </div>
                <div className="font-medium text-[#475467] text-[14px]">
                  Please tell us about the property you are receiving payments
                  on.
                </div>
                <div className="flex flex-col items-start justify-between">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    * Property Address
                  </h1>
                  <Form.Item
                    name={["propertyInformation", "address"]}
                    rules={[
                      {
                        required: true,
                        message: "Address is required!",
                      },
                      {
                        min: 7,
                        message: "Address should be minimum 7 letters!",
                      },
                    ]}
                    validateTrigger={
                      validationTrigger?.propertyInformation?.address
                    }
                    className="w-full"
                  >
                    <Input
                      placeholder="Enter Property Address"
                      className="w-full"
                    />
                  </Form.Item>
                </div>
                <div className="flex flex-col lg:flex-row justify-start items-start gap-6 lg:gap-10">
                  <div className="w-full">
                    {/* <h1 className="font-normal text-[14px] mb-[6px]">City</h1> */}
                    <Form.Item
                      name={["propertyInformation", "city"]}
                      validateTrigger={
                        validationTrigger?.propertyInformation?.city
                      }
                      className="w-full"
                      rules={[
                        {
                          required: true,
                          message: "City  is required!",
                        },
                        {
                          min: 2,
                          message: "City should be minimum 2 letter!",
                        },
                      ]}
                    >
                      <Input placeholder="City" className="w-full" />
                    </Form.Item>
                  </div>
                  <div className="w-full">
                    {/* <h1 className="font-normal text-[14px] mb-[6px]">State</h1> */}
                    <Form.Item
                      name={["propertyInformation", "state"]}
                      rules={[
                        {
                          required: true,
                          message: "State is required!",
                        },
                      ]}
                      className="w-full"
                      validateTrigger={
                        validationTrigger?.propertyInformation?.state
                      }
                    >
                      <AutoComplete
                        placeholder="Select State"
                        className="w-full"
                        options={usStates
                          .filter((stateName) =>
                            stateName
                              .toLowerCase()
                              .startsWith(inputValue.toLowerCase())
                          )
                          .map((stateName) => ({
                            value: stateName,
                            label: stateName,
                          }))}
                        onSearch={(value) => setInputValue(value)}
                      />
                    </Form.Item>
                  </div>
                  <div className="w-full">
                    {/* <h1 className="font-normal text-[14px] mb-[6px]">Zip Code</h1> */}
                    <Form.Item
                      name={["propertyInformation", "zipCode"]}
                      rules={[
                        {
                          required: true,
                          message: "Zip Code is required!",
                        },
                        {
                          validator: validateZipCode,
                        },
                      ]}
                      validateTrigger={
                        validationTrigger?.propertyInformation?.zipCode
                      }
                      className="w-full"
                    >
                      <InputNumber
                        controls="false"
                        placeholder="Zipcode"
                        className="w-full numberInput"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="w-full flex justify-between items-center">
                  <Form.Item>
                    <Button
                      type="default"
                      className="w-full bg-[#2F2B430D]"
                      onClick={() => {
                        setStep(1);
                      }}
                    >
                      Back
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      disabled={isButtonDisabled?.step_2}
                      className="w-full"
                      onClick={() => {
                        setStep(3);
                      }}
                    >
                      Next
                    </Button>
                  </Form.Item>
                </div>
              </div>
              {/* END Step 2 */}
              {/* START Step 3 */}
              <div
                className={`${
                  step == 3 ? "block" : "hidden"
                } flex flex-col gap-5 px-3`}
              >
                <div className="font-bold text-[#344054] text-[18px]">
                  Type of Property
                </div>
                <div className="flex flex-col items-start justify-between">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    * Type of Property
                  </h1>
                  <Form.Item
                    name={["typeOfProperty", "typeOfProperty"]}
                    rules={[
                      {
                        required: true,
                        message: "Please Select an Option!",
                      },
                    ]}
                    className="w-full"
                    validateTrigger={
                      validationTrigger?.typeOfProperty?.typeOfProperty
                    }
                  >
                    <Select name="type" placeholder="Please Select an Option">
                      {typeOfPropertyOptions?.map((option) => (
                        <Option value={option.value} key={option.text}>
                          {option.text}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between ">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    * Acreage
                  </h1>
                  <Form.Item
                    name={["typeOfProperty", "acreage"]}
                    rules={[
                      {
                        required: true,
                        message: "Please Select an Option!",
                      },
                    ]}
                    className="w-full"
                    validateTrigger={validationTrigger?.typeOfProperty?.acreage}
                  >
                    <Select name="type" placeholder="Please Select an Option">
                      {acreageOptions?.map((option) => (
                        <Option value={option.value} key={option.text}>
                          {option.text}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between ">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Occupancy
                  </h1>
                  <Form.Item
                    name={["typeOfProperty", "occupancy"]}
                    rules={[
                      {
                        required: true,
                        message: "Please Select an Option!",
                      },
                    ]}
                    className="w-full"
                    validateTrigger={
                      validationTrigger?.typeOfProperty?.occupancy
                    }
                  >
                    <Radio.Group className="flex flex-col gap-2">
                      {occupancyOptions?.map((option) => (
                        <Radio
                          key={option.value}
                          value={option.value}
                          className="font-medium text-[16px] text-[#344054]"
                        >
                          {option.text}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between  ">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Property Description
                  </h1>
                  <Form.Item
                    name={["typeOfProperty", "propertyDescription"]}
                    validateTrigger={
                      validationTrigger?.typeOfProperty?.propertyDescription
                    }
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Property Description  is required!",
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      placeholder="Enter your message"
                      className="w-full"
                    />
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Current Estimated Value of Property
                  </h1>
                  <Form.Item
                    name={["typeOfProperty", "currentEstimatedValue"]}
                    rules={[
                      {
                        required: true,
                        message: "Estimated Value is required!",
                      },
                      {
                        validator: amountValidation,
                      },
                    ]}
                    validateTrigger={"onChange"}
                    className="w-full"
                  >
                    <InputNumber
                      controls="false"
                      prefix="$"
                      placeholder="0000.00"
                      className="w-full numberInput"
                      formatter={formatInput}
                      precision={2}
                      // parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </div>
                <div className="w-full flex justify-between items-center">
                  <Form.Item>
                    <Button
                      type="default"
                      className="w-full bg-[#2F2B430D]"
                      onClick={() => {
                        setStep(2);
                      }}
                    >
                      Back
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      disabled={isButtonDisabled?.step_3}
                      className="w-full"
                      onClick={() => {
                        setStep(4);
                      }}
                    >
                      Next
                    </Button>
                  </Form.Item>
                </div>
              </div>
              {/* END Step 3 */}
              {/* START Step 4 */}
              <div
                className={`${
                  step == 4 ? "block" : "hidden"
                } flex flex-col gap-5 px-3`}
              >
                <div className="font-bold text-[#344054] text-[18px]">
                  Note Information
                </div>
                <div className="flex flex-col items-start justify-between">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Sale Date/Date of Note
                  </h1>

                  <Form.Item
                    name={["noteInformation", "saleDate"]}
                    validateTrigger={
                      validationTrigger?.noteInformation?.saleDate
                    }
                    className="my-2"
                  >
                    <DatePicker
                      placeholder="Select Date"
                      format="YYYY-MM-DD"
                      disabledDate={disabledFutureDates}
                    />
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between ">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Property Sale Price
                  </h1>
                  <Form.Item
                    name={["noteInformation", "propertySalePrice"]}
                    rules={[
                      {
                        required: true,
                        message: "Property Sale Price is required!",
                      },
                      {
                        validator: amountValidation,
                      },
                    ]}
                    validateTrigger={"onChange"}
                    className="w-full"
                  >
                    <InputNumber
                      controls="false"
                      placeholder="0000.00"
                      prefix="$"
                      className="w-full numberInput"
                      formatter={formatInput}
                      precision={2}
                    />
                  </Form.Item>
                </div>
                <div>
                  <div className="grid grid-cols-2 items-center">
                    <div>
                      <h2 className="mb-3 font-semibold text-[14px] text-[#344054]">
                        Down Payment Amount
                      </h2>
                    </div>

                    <div className="flex items-center justify-end">
                      <span
                        className={`mr-3 mb-3 ${
                          isDownPaymentNumeric
                            ? "text-[#98A2B3]"
                            : "text-[#344054]"
                        }`}
                      >
                        %
                      </span>
                      <Form.Item
                        name={["noteInformation", "isDownPaymentNumeric"]}
                      >
                        <Switch
                          className="mb-1"
                          style={
                            isDownPaymentNumeric
                              ? { backgroundColor: "#7F56D9" }
                              : { backgroundColor: "#12B76A" }
                          }
                          validateTrigger={
                            validationTrigger?.noteInformation
                              ?.isDownPaymentNumeric
                          }
                        />
                      </Form.Item>
                      <span
                        className={`mr-3 mb-3 ${
                          isDownPaymentNumeric
                            ? "text-[#344054]"
                            : "text-[#98A2B3]"
                        }`}
                      >
                        $
                      </span>
                    </div>
                  </div>
                  <div
                    className={`${isDownPaymentNumeric ? "block" : "hidden"}`}
                  >
                    <Form.Item
                      name={["noteInformation", "downPaymentAmountNumeric"]}
                      rules={[
                        {
                          required: isDownPaymentNumeric ? true : false,
                          message: "Down Payment Amount is required!",
                        },
                        {
                          type: "number",
                          message: "Down Payment Amount must be a number",
                          min: 0,
                          message:
                            "Down Payment Amount must be greater than or equal to 0!",
                        },
                        {
                          validator: amountValidation,
                        },
                      ]}
                      validateTrigger={"onChange"}
                      className="w-full"
                    >
                      <InputNumber
                        defaultValue={0}
                        prefix="$"
                        className="w-full numberInput"
                        // controls={false}
                        placeholder="0000.00"
                        formatter={formatInput}
                        precision={2}
                      />
                    </Form.Item>
                  </div>
                  <div
                    className={`${isDownPaymentNumeric ? "hidden" : "block"}`}
                  >
                    <Form.Item
                      name={["noteInformation", "downPaymentAmountPercentage"]}
                      rules={[
                        {
                          required: isDownPaymentNumeric ? false : true,
                          message: "Down Payment Amount is required!",
                        },
                        {
                          type: "number",
                          message: "Down Payment Amount must be a number",
                          message: "Down Payment Amount must be less than 100!",
                          max: 99,
                        },
                        {
                          type: "number",
                          message: "Down Payment Amount must be a number",
                          min: 0,
                          message:
                            "Down Payment Amount must be greater than or equal to 0!",
                        },
                      ]}
                      validateTrigger={
                        validationTrigger?.noteInformation
                          ?.downPaymentAmountPercentage
                      }
                      className="w-full"
                    >
                      <InputNumber
                        defaultValue={0}
                        suffix="%"
                        className="w-full numberInput"
                        controls={false}
                        placeholder="00.00"
                        precision={2}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-between ">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Original Starting Balance of Note
                  </h1>
                  <Form.Item
                    name={["noteInformation", "originalStartingBalance"]}
                    rules={[
                      {
                        required: true,
                        message: "Original Starting Balance is required!",
                      },
                      {
                        validator: amountValidation,
                      },
                    ]}
                    validateTrigger={"onChange"}
                    className="w-full"
                  >
                    <InputNumber
                      controls="false"
                      placeholder="0000.00"
                      prefix="$"
                      className="w-full numberInput"
                      formatter={formatInput}
                      precision={2}
                    />
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between ">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Current Principal Balance of Note
                  </h1>
                  <Form.Item
                    name={["noteInformation", "currentPrincipalBalance"]}
                    rules={[
                      {
                        required: true,
                        message: "Current Principal Balance is required!",
                      },
                      {
                        validator: amountValidation,
                      },
                    ]}
                    validateTrigger={"onChange"}
                    className="w-full"
                  >
                    <InputNumber
                      controls="false"
                      placeholder="0000.00"
                      prefix="$"
                      className="w-full numberInput"
                      formatter={formatInput}
                      precision={2}
                    />
                  </Form.Item>
                </div>

                <div className="flex flex-col items-start justify-between ">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Interest Rate
                  </h1>
                  <Form.Item
                    name={["noteInformation", "interestRate"]}
                    rules={[
                      {
                        required: true,
                        message: "Interest Rate is required!",
                      },
                      {
                        type: "number",
                        message: "Interest Rate must be a number",
                        max: 99,
                        message: "Interest Rate must be less than 100!",
                      },
                      {
                        type: "number",
                        message: "Interest Rate must be a number",
                        min: 0,
                        message:
                          "Interest Rate must be greater than or equal to 0!",
                      },
                    ]}
                    validateTrigger={
                      validationTrigger?.noteInformation?.interestRate
                    }
                    className="w-full"
                  >
                    <InputNumber
                      defaultValue={0}
                      suffix="%"
                      className="w-full numberInput"
                      controls={false}
                      placeholder="00.00"
                      precision={2}
                    />
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between ">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Principal & Interest Payment Amount
                  </h1>
                  <Form.Item
                    name={["noteInformation", "principalAndInterestPayment"]}
                    rules={[
                      {
                        required: true,
                        message: "Current Principal Balance is required!",
                      },
                      {
                        validator: amountValidation,
                      },
                    ]}
                    validateTrigger={"onChange"}
                    className="w-full"
                  >
                    <InputNumber
                      controls="false"
                      placeholder="0000.00"
                      prefix="$"
                      className="w-full numberInput"
                      formatter={formatInput}
                      precision={2}
                    />
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between ">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Payment Schedule?
                  </h1>
                  <Form.Item
                    name={["noteInformation", "paymentSchedule"]}
                    rules={[
                      {
                        required: true,
                        message: "Please Select an Option!",
                      },
                    ]}
                    className="w-full"
                    validateTrigger={
                      validationTrigger?.personalInformation?.paymentSchedule
                    }
                  >
                    <Select name="type" placeholder="Please Select an Option">
                      {paymentScheduleOptions?.map((option) => (
                        <Option value={option.value} key={option.text}>
                          {option.text}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between ">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Next Payment Due Date
                  </h1>

                  <Form.Item
                    name={["noteInformation", "nextPaymentDueDate"]}
                    validateTrigger={
                      validationTrigger?.noteInformation?.nextPaymentDueDate
                    }
                    className="my-2"
                  >
                    <DatePicker
                      placeholder="Select Date"
                      format="YYYY-MM-DD"
                      disabledDate={disabledPastDates}
                    />
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Is There an "All Due" or "Ballon Payment" Date?
                  </h1>
                  <Form.Item
                    name={["noteInformation", "ballonPayment"]}
                    rules={[
                      {
                        required: true,
                        message: "Please Select an Option!",
                      },
                    ]}
                    className="w-full"
                    validateTrigger={
                      validationTrigger?.noteInformation?.ballonPayment
                    }
                  >
                    <Radio.Group className="flex flex-col gap-2">
                      {ballonPaymentDateOptions?.map((option) => (
                        <Radio
                          key={option.value}
                          value={option.value}
                          className="font-semibold text-[14px]"
                        >
                          {option.text}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between ">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Are Payments Current?
                  </h1>
                  <Form.Item
                    name={["noteInformation", "arePaymentsCurrent"]}
                    rules={[
                      {
                        required: true,
                        message: "Please Select an Option!",
                      },
                    ]}
                    className="w-full"
                    validateTrigger={
                      validationTrigger?.noteInformation?.arePaymentsCurrent
                    }
                  >
                    <Radio.Group className="flex flex-col gap-2">
                      {paymentsCurrentOptions?.map((option) => (
                        <Radio
                          key={option.value}
                          value={option.value}
                          className="font-semibold text-[14px]"
                        >
                          {option.text}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Who Collects Payments?
                  </h1>
                  <Form.Item
                    name={["noteInformation", "whoCollectsPayment"]}
                    rules={[
                      {
                        required: true,
                        message: "Please Select an Option!",
                      },
                    ]}
                    className="w-full"
                    validateTrigger={
                      validationTrigger?.noteInformation?.whoCollectsPayment
                    }
                  >
                    <Radio.Group className="flex flex-col gap-2">
                      {whoCollectsOptions?.map((option) => (
                        <Radio
                          key={option.value}
                          value={option.value}
                          className="font-semibold text-[14px]"
                        >
                          {option.text}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Is This A First Position Note?
                  </h1>
                  <Form.Item
                    name={["noteInformation", "isFirstPositionNote"]}
                    rules={[
                      {
                        required: true,
                        message: "Please Select an Option!",
                      },
                    ]}
                    className="w-full"
                    validateTrigger={
                      validationTrigger?.noteInformation?.isFirstPositionNote
                    }
                  >
                    <Radio.Group className="flex flex-col gap-2">
                      {firstPositionNotesOptions?.map((option) => (
                        <Radio
                          key={option.value}
                          value={option.value}
                          className="font-semibold text-[14px]"
                        >
                          {option.text}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className="w-full flex justify-between items-center">
                  <Form.Item>
                    <Button
                      type="default"
                      className="w-full bg-[#2F2B430D]"
                      onClick={() => {
                        setStep(3);
                      }}
                    >
                      Back
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      disabled={isButtonDisabled?.step_4}
                      className="w-full"
                      onClick={() => {
                        setStep(5);
                      }}
                    >
                      Next
                    </Button>
                  </Form.Item>
                </div>
              </div>
              {/* END Step 4 */}
              {/* Start Step 5 */}
              <div
                className={`${
                  step == 5 ? "block" : "hidden"
                } flex flex-col gap-5 px-3`}
              >
                <div className="font-bold text-[#344054] text-[18px]">
                  Additional Information
                </div>
                <div className="flex flex-col items-start justify-between">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Please Let Us Know Any Special Circumstances Or Reasons For
                    Selling The Note
                  </h1>
                  <Form.Item
                    name={["additionalInformation", "reasonsForSelling"]}
                    validateTrigger={
                      validationTrigger?.additionalInformation
                        ?.reasonsForSelling
                    }
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please define your reason for selling!",
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      placeholder="Enter your message"
                      className="w-full"
                    />
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Copies of Documents
                  </h1>
                  <Form.Item
                    name={["additionalInformation", "copyOfDocs"]}
                    validateTrigger={
                      validationTrigger?.additionalInformation?.copyOfDocs
                    }
                    className="w-full border-2 border-dotted border-[#2F2B431A] flex justify-center items-center py-5 px-8 rounded-lg"
                    rules={[
                      {
                        required: true,
                        message: "Please attach a copy of documents!",
                      },
                    ]}
                  >
                    <div className="flex flex-col justify-center items-center gap-3">
                      <div className="text-[#475467] font-semibold text-[14px]">
                        Select a file or drag and drop here
                      </div>
                      <div className="text-[#98A2B3] text-[12px]">
                        JPG, PNG, or PDF, file size no more than 10 MB
                      </div>
                      <Upload
                        accept=".jpg,.png,.pdf"
                        beforeUpload={beforeUpload}
                      >
                        <Button
                          className="font-bold bg-[#2F2B430D] outline-none"
                          loading={fileUploading}
                        >
                          Select File
                        </Button>
                      </Upload>
                    </div>
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between ">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Are you providing copy of documents at this time?
                  </h1>
                  <Form.Item
                    name={["additionalInformation", "isCopyOfDocProviding"]}
                    rules={[
                      {
                        required: true,
                        message: "Please Select an Option!",
                      },
                    ]}
                    className="w-full"
                    validateTrigger={
                      validationTrigger?.additionalInformation
                        ?.isCopyOfDocProviding
                    }
                  >
                    <Radio.Group className="flex flex-col gap-2">
                      {copiesOfDocsOptions?.map((option) => (
                        <Radio
                          key={option.value}
                          value={option.value}
                          className="font-semibold text-[14px]"
                        >
                          {option.text}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className="flex flex-col items-start justify-between ">
                  <h1 className="font-semibold text-[14px] text-[#344054] mb-[6px]">
                    Additional Comments
                  </h1>
                  <Form.Item
                    name={["additionalInformation", "additionalComments"]}
                    validateTrigger={
                      validationTrigger?.additionalInformation
                        ?.additionalComments
                    }
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Additional Comments  is required!",
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      placeholder="Enter your message"
                      className="w-full"
                    />
                  </Form.Item>
                </div>
                <div className="w-full flex justify-between items-center">
                  <Form.Item>
                    <Button
                      type="default"
                      className="w-full bg-[#2F2B430D]"
                      onClick={() => {
                        setStep(4);
                      }}
                    >
                      Back
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type={"primary"}
                      htmlType="submit"
                      disabled={isButtonDisabled?.step_5}
                      className="w-full"
                      loading={uploadingData}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </div>
              {/* End Step 5 */}
            </>
          ) : (
            <div className="flex flex-col justify-center items-center gap-10">
              <div className="font-bold text-[16px] text-black">
                {pageData?.thankYou?.text}
              </div>
              <img
                src={`${pageData?.thankYou?.image?.data?.attributes?.url}`}
                alt="contact-img"
                width={700}
                height={700}
              />
              <Button
                type="primary"
                onClick={() => navigate(pageData?.thankYou?.link)}
              >
                {pageData.thankYou.btnText}
              </Button>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};
export default QuoteForm;
