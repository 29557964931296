import { useEffect, useRef, useState } from "react";
import axios from "axios";
import QuoteForm from "../../components/QuoteForm";
import PageLoader from "../../components/PageLoader";
import { useLocation } from "react-router-dom";

const ContactUs = () => {
  const parentRef = useRef();
  const { state } = useLocation();
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(false);

  // Methods
  const getPageData = async () => {
    try {
      setLoading(true);
      const response = await axios({
        url: `${process.env.REACT_APP_STRAPI_URL}/api/fund-contact-us/1`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
        params: {
          populate: {
            contactMethods: {
              populate: ["image"],
            },
            thankYou: {
              populate: ["image"],
            },
          },
        },
      });

      if (
        response.status === 200 &&
        response?.data?.data?.attributes?.publishedAt != null
      ) {
        setPageData(response?.data?.data?.attributes);
        setLoading(false);
        if (state == "focus") {
          handleGetAQuote();
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("Error:~", error);
      return;
    }
  };

  // Effects
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getPageData();
  }, []);
  const handleGetAQuote = () => {
    if (parentRef?.current) {
      const parentElement = parentRef.current;
      const elementTop =
        parentElement.getBoundingClientRect().top + window.scrollY;
      const offset = elementTop - window.innerHeight / 2;
      window.scrollTo({ top: offset + 160, behavior: "smooth" });
    }
  };

  if (loading) return <PageLoader />;

  return (
    <div className="w-full">
      <div className="w-full bg-[#FCFCFD]">
        <div className="w-full md:w-5/6 mx-auto px-5 md:px-0 flex flex-col items-center py-[96px]">
          {pageData?.subText && (
            <h1 className="mb-[12px] text-[#7f56d9] font-semibold text-[16px]">
              {pageData.subText}
            </h1>
          )}
          {pageData?.title && (
            <h1 className="mb-[24px] font-semibold text-[40px] text-center">
              {pageData.title}
            </h1>
          )}
          {pageData?.description && (
            <p className="mb-[64px] w-full md:w-[50%] text-[#475467] font-normal text-[20px] text-center">
              {pageData.description}
            </p>
          )}
          {pageData?.title && (
            <button
              onClick={handleGetAQuote}
              className="w-full md:w-[200px] py-2 px-4 bg-[#3364F6] text-white text-[18px] font-medium rounded-[12px]"
            >
              GET A QUOTE!
            </button>
          )}
          <div className="w-full flex flex-col lg:flex-row lg:gap-0 justify-between items-center my-20 gap-y-6">
            {pageData?.contactMethods?.map((item, index) => (
              <div
                key={index}
                className="w-[384px] flex flex-col justify-center items-center gap-3"
              >
                <div className="w-[48px] h-[48px] rounded-full bg-[#F9F5FF] flex justify-center items-center">
                  <div className="w-[36px] h-[36px] rounded-full bg-[#F4EBFF] flex justify-center items-center">
                    {item?.image?.data?.attributes?.url && (
                      <img src={`${item.image.data.attributes.url}`} />
                    )}
                  </div>
                </div>
                {item?.title && (
                  <div className="text-[20px] font-bold text-black">
                    {item.title}
                  </div>
                )}
                {item?.text && (
                  <div className="text-[16px] text-[#475467] text-center">
                    {item.text}
                  </div>
                )}
                {item?.description && (
                  <div className="text-[16px] text-[#3364F6] font-semibold text-center">
                    {item.description}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="w-full md:w-5/6 mx-auto pt-5 pb-24 flex flex-col items-center gap-14"
        ref={parentRef}
      >
        <div className="flex flex-col items-center gap-3">
          <div className="font-bold text-[36px] text-center">
            Quote request worksheet
          </div>
          <div className="text-[#475467] text-[20px]">
            "*" indicates required fields
          </div>
        </div>
        <QuoteForm pageData={pageData} />
      </div>
    </div>
  );
};
export default ContactUs;
