import { useEffect, useState } from "react";
import PageLoader from "../../components/PageLoader";
import axios from "axios";
import { Input, Button, Form, Checkbox, notification } from "antd";
import { useForm } from "sunflower-antd";
import { isValidPhoneNumber } from "react-phone-number-input";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import ArrowRight from "../../assets/svg/ArrowRight";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import TextArea from "antd/es/input/TextArea";
import HomeCarasoul from "../../components/HomeCarasoul";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.scss";

const Home = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [pageData, setPageData] = useState({});
  const [retrievingPageData, setRetrievingPageData] = useState(false);

  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const { formProps, formValues, formResult, formLoading, submit } = useForm({
    form,
    async submit(formValues) {
      await new Promise((r) => setTimeout(r, 1000));
      return "ok";
    },
  });

  //Start Methods
  const getCroppedImageStyle = (imageSrc) => {
    const aspectRatio = 17 / 16;
    const modifiedSrc = `${imageSrc}`;
    const width = "100%";
    const height = 600 / aspectRatio;
    return {
      backgroundImage: `url(${modifiedSrc})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: `${width}%`,
      height: `${height}px`,
      borderTopLeftRadius: "40px",
    };
  };

  const [validationTrigger, setValidationTrigger] = useState({
    firstName: ["onBlur"],
    lastName: ["onBlur"],
    email: ["onBlur"],
    phoneNumber: ["onBlur"],
    message: ["onBlur"],
    privacyCheck: ["onBlur"],
  });

  const getPageData = async () => {
    try {
      setRetrievingPageData(true);
      const response = await axios({
        url: `${process.env.REACT_APP_STRAPI_URL}/api/fund-homes/1`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
        params: {
          populate: {
            button: {
              populate: ["title", "text", "link"],
            },
            story: {
              populate: ["title", "description", "subText", "image"],
            },
            thankYou: {
              populate: ["text", "image", "btnText", "link"],
            },
            features: {
              populate: ["title", "text", "description", "image"],
            },
          },
        },
      });

      if (response.status === 200 && response?.data?.data?.attributes?.publishedAt != null) {
        setPageData(response?.data?.data?.attributes)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error:~", error);
      setRetrievingPageData(false);
      return;
    }
  };

  const handlePhoneNumberChange = (value) => {
    setValue(value);
  };
  const validatePhoneNumber = (number) => {
    const isValid = isValidPhoneNumber(`+${number}`);
    return isValid;
  };

  const handleFieldsChange = (_, allFields) => {
    if (_?.length) {
      const currentObj = _[0];
      if (
        currentObj?.validated &&
        currentObj?.errors?.length &&
        currentObj?.name?.length
      ) {
        const main = currentObj.name[0];
        const validator = validationTrigger;
        if (!validator[`${main}`].includes("onChange")) {
          validator[`${main}`].push("onChange");
          setValidationTrigger({
            ...validator,
          });
        }
      }
    }
  };
  const handleValuesChange = (_, allFields) => {

    try {
      if (
        allFields?.phoneNumber &&
        allFields?.firstName &&
        allFields?.email &&
        allFields?.message &&
        validatePhoneNumber(allFields?.phoneNumber) &&
        allFields?.privacyCheck
      ) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    } catch (error) {
      setIsButtonDisabled(true);
    }
  };

  const authenticate = async () => {
    try {
      const data = {
        grant_type: "password",
        username: process.env.REACT_APP_PODIO_ADMIN_USERNAME,
        password: process.env.REACT_APP_PODIO_ADMIN_PASSWORD,
        client_id: process.env.REACT_APP_CONTACT_INFO_APP_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_PODIO_REDIRECT_URI,
        client_secret: process.env.REACT_APP_CONTACT_INFO_APP_CLIENT_SECRET,
      };
      const response = await axios({
        url: "https://api.podio.com/oauth/token/v2",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          grant_type: "password",
          username: process.env.REACT_APP_PODIO_ADMIN_USERNAME,
          password: process.env.REACT_APP_PODIO_ADMIN_PASSWORD,
          client_id: process.env.REACT_APP_CONTACT_INFO_APP_CLIENT_ID,
          redirect_uri: process.env.REACT_APP_PODIO_REDIRECT_URI,
          client_secret: process.env.REACT_APP_CONTACT_INFO_APP_CLIENT_SECRET,
        },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log("Error", error.response.data);
      return;
    }
  };

  const storeDataInPodio = async (accessToken, values) => {
    try {
      const appId = process.env.REACT_APP_CONTACT_INFO_APP_ID;
      const url = `https://api.podio.com/item/app/${appId}/`;
      const payload = {
        fields: {
          "full-name": `${values?.firstName} ${values?.lastName}`,
          email: [{ type: "work", value: values?.email }],
          "phone-number": [{ type: "work", value: values?.phoneNumber }],
          message: values?.message,
          "you-agree-to-our-friendly-privacy-policy": values?.privacyCheck
            ? 1
            : 2,
        },
      };

      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `OAuth2 ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error storing data in Podio:", error);
      return;
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    const authorization = await authenticate();
    if (!authorization) {
      setLoading(false);
      return;
    }
    const data = await storeDataInPodio(authorization?.access_token, values);
    if (!data) {
      setLoading(false);
      return;
    }
    setLoading(false);
    form.resetFields();
    notification.success({
      message: "Success",
      description: "Information sent successfully",
    });
    setFormSubmitted(true);
  };

  const buttonOnClickHandler = () => navigate(pageData?.button?.link);

  // Effects
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getPageData();
  }, []);

  const formTitleText = pageData && pageData?.formTitle?.split("?");
  const handleNavigation = (link, target) => {
    if (link) {
      if (target) {
        window.location.href = link;
      } else {
        navigate(`${link}`,{state:"focus"});
      }
    }
  };

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <div className="homePage">
          {pageData?.publishedAt != null && (
            <>
              <div className="bg-upper md:pt-[158px] w-full">
                <div className="w-full bg-overlay"></div>
                <div className="z-[100] w-5/6 mx-auto flex flex-col items-center pb-[0px]">
                  {pageData?.title && (
                    <h1 className="font-bold text-[32px] md:text-[55px] text-center mt-[50px]">
                      {pageData.title}
                    </h1>
                  )}
                  {pageData?.description && (
                    <h1 className="font-medium text-[20px] text-center w-full md:w-[37%] mt-[24px] mb-[32px] text-[#2F2B4399]">
                      {pageData?.description}
                    </h1>
                  )}
                  {pageData?.subText && (
                    <h1 className="font-normal text-[16px] text-center mb-[32px] talk-text">
                      <span className="mx-3">{pageData.subText}</span>
                    </h1>
                  )}
                </div>
              </div>
              <div className="w-full flex justify-between">
                {pageData?.button?.id && (
                  <button
                    onClick={() =>
                      handleNavigation(
                        pageData.button.link,
                        pageData.button.targetBlank
                      )
                    }
                    style={{ zIndex: "-600 !important" }}
                    className="py-2 w-full mx-auto md:w-auto px-4 bg-[#3364F6] text-white text-[16px] font-medium rounded-[12px]"
                  >
                    {pageData.button.text ?? "Get A Quote"}
                  </button>
                )}
              </div>
              <div
                className="mt-[100px] w-5/6 mx-auto bg-[#F4F7FE] rounded-3xl flex flex-col md:flex-row md:justify-between md:items-center md:pt-[50px] overflow-hidden"
                style={{ border: "1px solid #2F2B431A" }}
              >
                <div className="md:w-[48%] px-5 py-5 md:px-[80px] md:my-[50px]">
                  {pageData?.story?.title && (
                    <h1 className="font-bold text-[36px] md:text-[40px] text-left text-[#2F2B43]">
                      {pageData.story.title}
                    </h1>
                  )}
                  {pageData?.story?.description && (
                    <p className="mt-[50px] text-[#2f2b4399] font-normal text-[16px]">
                      <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                        {pageData.story.description}
                      </ReactMarkdown>
                    </p>
                  )}
                  {pageData?.button?.id && (
                    <button
                      onClick={() =>
                        handleNavigation(
                          pageData.button.link,
                          pageData.button.targetBlank
                        )
                      }
                      className="py-2 px-4 font-semibold rounded-[12px] mt-[20px] flex justify-center items-center bg-[#2f2b430d]"
                    >
                      <span className="mr-2 font-medium text-[16px]">
                        {pageData.button.text ?? "Get A Quote"}
                      </span>
                      <ArrowRight />
                    </button>
                  )}
                </div>
                {pageData?.story?.image?.data?.attributes?.url && (
                  <div className="w-full md:w-[52%] pl-5 md:pl-0 mt-auto">
                    <div
                      style={getCroppedImageStyle(
                        pageData.story.image.data.attributes.url
                      )}
                    ></div>
                  </div>
                )}
              </div>
            </>
          )}
          {pageData?.features?.length > 0 && (
            <div className="w-full px-5 md:px-0 md:w-5/6 mx-auto mt-[128px]">
              <h1 className="text-[36px] md:text-[42px] font-semibold text-center mb-[24px]">
                {pageData?.featuresTitle}
              </h1>
              <p className="font-normal text-[18px] text-[#2f2b4399] mx-auto w-full md:w-[45%] text-center">
                {pageData?.featuresText}
              </p>
              <div className="w-full flex flex-col lg:flex-row lg:gap-0 justify-between items-center my-20 gap-y-6">
                {pageData.features.map((quoteStep, index) => {
                  return (
                    <div
                      className="flex flex-col w-full md:w-[23%] justify-start items-center"
                      id={`quote-${index}`}
                    >
                      <div className="workBox w-[48px] h-[48px] flex justify-center items-center mb-[24px] rounded-[10px]">
                        <img src={quoteStep?.image?.data?.attributes?.url} />
                      </div>
                      <h1 className="font-semibold text-[20px] text-center">
                        {quoteStep.title}
                      </h1>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="w-full bg-[#fcfcfd] py-[64px]">
            <div className="w-5/6 mx-auto">
              {formTitleText && (
                <div className="flex flex-col items-center w-full">
                  <span className="font-semibold text-[42px] mb-0 leading-5">
                    {formTitleText && formTitleText[0]}?
                  </span>
                  <span className="font-semibold text-[42px] mt-0 text-center">
                    {formTitleText && formTitleText[1]}
                  </span>
                </div>
              )}
              {pageData?.formText && (
                <div className="flex justify-center w-full md:w-[45%] mx-auto">
                  <p className="text-center font-normal text-[18px] text-[#2f2b4399]">
                    {pageData.formText}
                  </p>
                </div>
              )}
              <div className="flex w-full md:w-[51%] justify-center mx-auto pt-[64px]">
                {!formSubmitted ? (
                  <Form
                    layout="inline"
                    {...formProps}
                    onFinish={onFinish}
                    onFieldsChange={handleFieldsChange}
                    className="flex flex-col mx-2 w-full"
                    style={{ maxWidth: "100%", md: 900 }}
                    onValuesChange={handleValuesChange}
                  >
                    <div className="flex justify-between gap-2">
                      <div className="flex flex-col items-start justify-between xl:w-[45%]  lg:w-[45%] md:w-full sm:w-full">
                        <h1 className="font-normal text-[14px] mb-[6px]">
                          First Name
                        </h1>
                        <Form.Item
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: "First Name is required!",
                            },
                            {
                              min: 1,
                              message: "First Name should be minimum 1 letter!",
                            },
                          ]}
                          validateTrigger={validationTrigger.firstName}
                          className="w-full"
                        >
                          <Input
                            placeholder="Enter First Name"
                            className="w-full"
                          />
                        </Form.Item>
                      </div>
                      <div className="flex flex-col items-start justify-between xl:w-[45%]  lg:w-[45%] md:w-full sm:w-full">
                        <h1 className="font-normal text-[14px] mb-[6px]">
                          Last Name
                        </h1>
                        <Form.Item
                          name="lastName"
                          validateTrigger={validationTrigger.lastName}
                          className="w-full"
                        >
                          <Input
                            placeholder="Enter Last Name"
                            className="w-full"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="flex flex-col items-start justify-between mt-[24px]">
                      <h1 className="font-normal text-[14px] mb-[6px]">
                        Email
                      </h1>
                      <Form.Item
                        name="email"
                        validateTrigger={validationTrigger.email}
                        className="w-full"
                        rules={[
                          { type: "email" },
                          {
                            required: true,
                            message: "Please enter a valid email",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Email Address"
                          className="w-full"
                        />
                      </Form.Item>
                    </div>
                    <div className="flex flex-col items-start justify-between mt-[24px]">
                      <h1 className="font-normal text-[14px] mb-[6px]">
                        Phone number
                      </h1>
                      <Form.Item
                        name="phoneNumber"
                        // label='Phone number'
                        rules={[
                          {
                            required: true,
                            message: "Please input your phone number!",
                          },
                        ]}
                        className="w-full"
                      >
                        <PhoneNumberInput
                          value={value}
                          onChange={handlePhoneNumberChange}
                        />
                      </Form.Item>
                    </div>
                    <div className="flex flex-col items-start justify-between mt-[24px]">
                      <h1 className="font-normal text-[14px] mb-[6px]">
                        Message
                      </h1>
                      <Form.Item
                        name="message"
                        // label='Message'
                        rules={[
                          {
                            required: true,
                            message: "Message is required!",
                          },
                        ]}
                        className="w-full"
                      >
                        <TextArea
                          rows={4}
                          placeholder="Enter your message"
                          className="w-full"
                        />
                      </Form.Item>
                    </div>
                    <div className="flex flex-col items-start justify-between mt-[24px]">
                      {/* <h1>First Name</h1> */}
                      <Form.Item
                        name="privacyCheck"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject("Should accept agreement"),
                          },
                        ]}
                        className="w-full"
                      >
                        <Checkbox className="w-full">
                          <span className="font-normal text-[16px]">
                            You agree to our friendly
                            <Link
                              to="/privacy"
                              className="ml-[4px] underline"
                              target="blank"
                            >
                              privacy policy.
                            </Link>
                          </span>
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div className="flex flex-col items-start justify-between mt-[32px]">
                      <Form.Item className="w-full">
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={isButtonDisabled}
                          className="w-full"
                          loading={loading}
                        >
                          Send message
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                ) : (
                  <div className="flex flex-col justify-center items-center gap-10">
                    <div className="font-bold text-[16px] text-black">
                      {pageData?.thankYou?.text ?? "Thanks for Submitting data"}
                    </div>
                    {pageData?.thankYou?.image?.data?.attributes?.url && (
                      <img
                        src={`${pageData.thankYou.image.data.attributes.url}`}
                        alt="contact-img"
                        width={700}
                        height={700}
                      />
                    )}
                    <Button
                      type="primary"
                      onClick={() => navigate(pageData?.thankYou?.link??"/contact-us")}
                    >
                      {pageData?.thankYou?.btnText ?? "Contact us"}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full mb-[100px]">
            <HomeCarasoul />
          </div>
        </div>
      )}
    </>
  );
};
export default Home;
